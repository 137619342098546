import styled from 'styled-components';

interface ICheckboxInput {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CheckboxInput = styled.label<ICheckboxInput>`
  display: flex;
  align-items: center;

  span {
    color: #202020;
    font-weight: 500;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 8px;
    transition-duration: 0.3s;
    border: 1px solid #6d6d6d;
  }

  .inputData {
    display: none;
  }

  + label {
    margin-left: 20px;
  }
`;

export const Error = styled.span`
  font-size: 11px !important;
`;

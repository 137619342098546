import React, { createContext, useCallback, useState, useContext } from 'react';
import { AxiosResponse } from 'axios';

import { useHistory } from 'react-router-dom';
import api from '../services/api';

interface IAffiliatesProducts {
  id: string;
  product_id: string;
  product: {
    name: string;
    lines: number;
  };
}

interface IAddress {
  id: string;
  city: string;
  complement: string;
  country: string;
  neighborhood?: string;
  number: string;
  state: string;
  street: string;
  zip_code: string;
}
export interface User {
  id: string;
  customer_id: string;
  temp_pass: string;
  name: string;
  email: string;
  username: string;
  phone: string;
  referral_code: string;
  email_notifications: boolean;
  sms_notifications: boolean;
  created_at: string;
  type?: string;
  avatar: {
    id: string;
    avatar_url: string;
  };
  address?: IAddress;
  affiliate: {
    id: string;
    user_id: string;
    product_id: string;
    active_affiliate: boolean;
    affiliate_commission: number;
    crowdfunding_participation: boolean;
    crowdfunding_share: number;
    franchise_owner: boolean;
    franchise_commission: number;
    type: string;
    affiliatesProducts?: IAffiliatesProducts[];
    wallet: {
      id: string;
      amount: string;
      status: string;
      request_amount: string;
    };
    affiliatesOffers: {
      offer_id: string;
    }[];
  };
  waitlist?: {
    id: string;
    position: number;
  };
  referrer?: {
    affiliate_id: string;
    affiliate: {
      user: {
        name: string;
      };
    };
  };
  termUser?: {
    user_id: string;
    name: string;
    ip: string;
    date_signed: string;
  };
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface IWalletHistory {
  type: string;
  date: string;
  amount: string;
  withdrawal?: boolean;
}

interface IGiftCard {
  id: string;
  status: string;
  card: string;
  amount: string;
}

interface AuthContextData {
  user: User;
  walletPrice: number;
  setWalletPrice(amount: number): void;
  walletHistory: IWalletHistory[];
  setWalletHistory(walletHistory: IWalletHistory[]): void;
  giftCards: IGiftCard[];
  setGiftCards(giftCards: IGiftCard[]): void;
  signIn(credentials: SignInCredentials, routeAccess?: string): Promise<void>;
  signInWithPass(user_id: string, routeAccess?: string): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  setLogin(tokenData: string, user: User, cacheToken: string): void;
  credentials: SignInCredentials | undefined;
  setCredentials: React.Dispatch<
    React.SetStateAction<SignInCredentials | undefined>
  >;
  autoLogin(token: string, type: string, path?: string): Promise<void>;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@AutoAffiliate:token');
    const user = localStorage.getItem('@AutoAffiliate:user');

    if (token && user) {
      api.defaults.headers.authorization = `Barear ${token}`;
      const userData = JSON.parse(user);
      return { token, user: { ...userData, name: userData.name || 'Pending' } };
    }

    return {} as AuthState;
  });
  const [walletPrice, setWalletPrice] = useState(0);
  const [walletHistory, setWalletHistory] = useState<IWalletHistory[]>([]);
  const [giftCards, setGiftCards] = useState<IGiftCard[]>([]);
  const [credentials, setCredentials] = useState<SignInCredentials | undefined>(
    undefined
  );

  const signIn = useCallback(
    async ({ email, password }, routeAccess) => {
      const response = await api.post<AuthState>('users/sessions', {
        email,
        password,
      });

      const { token, user } = response.data;

      let type = 'New Member';
      let affiliate = false;

      if (
        user.affiliate &&
        user.affiliate.affiliatesOffers &&
        user.affiliate.affiliatesOffers.length > 0
      ) {
        affiliate = !!user.affiliate.affiliatesOffers.find(
          (affiliateOffer) =>
            affiliateOffer.offer_id === '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
        );

        const superAffiliate = !!user.affiliate.affiliatesOffers.find(
          (affiliateOffer) =>
            affiliateOffer.offer_id === 'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
        );

        if (superAffiliate) {
          type = 'Super Affiliate';
        } else if (affiliate) {
          type = 'Affiliate';
        }
      }

      user.type = type;

      localStorage.setItem('@AutoAffiliate:token', token);
      localStorage.setItem('@AutoAffiliate:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Barear ${token}`;

      setData({ token, user });

      if (routeAccess) {
        setCredentials({ email, password });
        history.push(`${process.env.PUBLIC_URL}/${routeAccess}`);
      }
    },
    [history]
  );

  const autoLogin = useCallback(
    async (tokenData: string, typeData: string, routeAccess?: string) => {
      let response: AxiosResponse<AuthState>;

      if (typeData === 'admin') {
        response = await api.get<AuthState>(
          `users/admins/session/${tokenData}`
        );
      } else {
        response = await api.get<AuthState>(
          `users/sessions/auto-login/${tokenData}`
        );
      }

      const { token, user } = response.data;

      let type = 'New Member';
      let affiliate = false;

      if (
        user.affiliate &&
        user.affiliate.affiliatesOffers &&
        user.affiliate.affiliatesOffers.length > 0
      ) {
        affiliate = !!user.affiliate.affiliatesOffers.find(
          (affiliateOffer) =>
            affiliateOffer.offer_id === '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
        );

        const superAffiliate = !!user.affiliate.affiliatesOffers.find(
          (affiliateOffer) =>
            affiliateOffer.offer_id === 'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
        );

        if (superAffiliate) {
          type = 'Super Affiliate';
        } else if (affiliate) {
          type = 'Affiliate';
        }
      }

      user.type = type;

      localStorage.setItem('@AutoAffiliate:token', token);
      localStorage.setItem('@AutoAffiliate:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Barear ${token}`;

      setData({ token, user });

      if (routeAccess) {
        history.push(`${process.env.PUBLIC_URL}${routeAccess}`);
      }
    },
    [history]
  );

  const signInWithPass = useCallback(async (user_id) => {
    const response = await api.post<AuthState>(`users/sessions/${user_id}`, {
      user_id,
    });

    const { token, user } = response.data;

    let type = 'New Member';
    let affiliate = false;

    if (
      user.affiliate &&
      user.affiliate.affiliatesOffers &&
      user.affiliate.affiliatesOffers.length > 0
    ) {
      affiliate = !!user.affiliate.affiliatesOffers.find(
        (affiliateOffer) =>
          affiliateOffer.offer_id === '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
      );

      const superAffiliate = !!user.affiliate.affiliatesOffers.find(
        (affiliateOffer) =>
          affiliateOffer.offer_id === 'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
      );

      if (superAffiliate) {
        type = 'Super Affiliate';
      } else if (affiliate) {
        type = 'Affiliate';
      }
    }

    user.type = type;

    localStorage.setItem('@AutoAffiliate:token', token);
    localStorage.setItem('@AutoAffiliate:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Barear ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    const cacheToken = localStorage.getItem('@AutoAffiliate:cacheToken');
    localStorage.removeItem('@AutoAffiliate:token');
    localStorage.removeItem('@AutoAffiliate:user');
    localStorage.removeItem('@AutoAffiliate:cacheToken');

    if (cacheToken) {
      api.get(`affiliates/sessions/remove-data/${cacheToken}`);
    }

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@AutoAffiliate:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  const setLogin = useCallback(
    (token, user, cacheToken) => {
      let type = 'New Member';
      let affiliate = false;

      if (
        user.affiliate &&
        user.affiliate.affiliatesOffers &&
        user.affiliate.affiliatesOffers.length > 0
      ) {
        affiliate = !!user.affiliate.affiliatesOffers.find(
          (affiliateOffer: { offer_id: string }) =>
            affiliateOffer.offer_id === '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
        );

        const superAffiliate = !!user.affiliate.affiliatesOffers.find(
          (affiliateOffer: { offer_id: string }) =>
            affiliateOffer.offer_id === 'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
        );

        if (superAffiliate) {
          type = 'Super Affiliate';
        } else if (affiliate) {
          type = 'Affiliate';
        }
      }

      // eslint-disable-next-line no-param-reassign
      user.type = type;

      localStorage.setItem('@AutoAffiliate:token', token);
      localStorage.setItem('@AutoAffiliate:user', JSON.stringify(user));
      localStorage.setItem('@AutoAffiliate:cacheToken', cacheToken);

      api.defaults.headers.authorization = `Barear ${token}`;

      if (user.temp_pass) {
        setCredentials({ email: user.email, password: user.temp_pass });
      }

      setData({
        token,
        user,
      });
    },
    [setData]
  );

  const handleChangeWallet = useCallback(
    async (value) => {
      setWalletPrice(value);
      const formData = {
        user_id: data.user.id,
        amount: value,
      };

      await api.put(
        `wallets/affiliates/${data.user.affiliate.wallet.id}`,
        formData
      );
    },
    [data]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        walletPrice,
        setWalletPrice: handleChangeWallet,
        walletHistory,
        setWalletHistory,
        giftCards,
        setGiftCards,
        signIn,
        signInWithPass,
        signOut,
        updateUser,
        setLogin,
        credentials,
        setCredentials,
        autoLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { format } from 'date-fns';
import { VscDesktopDownload } from 'react-icons/vsc';
// import { PDFDownloadLink } from '@react-pdf/renderer';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useAuth } from '../Auth';

import { Modal } from './styles';
import InputCheckbox from '~/components/InputCheckbox';
import GeneratePDF from './GeneratePDF';
import api from '~/services/api';
import scrollDown from '~/assets/animations/scroll-down.json';
import ArrowDown from '~/assets/animations/arrow-down.json';

interface TermsContextData {}

export const TermsContext = createContext<TermsContextData>(
  {} as TermsContextData
);

export const TermsProvider: React.FC = ({ children }) => {
  const { user, updateUser, signOut } = useAuth();
  const history = useHistory();
  const scroll = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState('');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [dateSigned, setDateSigned] = useState('');
  const [ip, setIp] = useState('');
  const [isAffiliate, setIsAffiliate] = useState(false);

  useEffect(() => {
    axios.get('https://api.ipify.org?format=json').then((response) => {
      setIp(response.data.ip);
    });
  }, []);

  useEffect(() => {
    api
      .get('orders/users/b060defe-695a-4ddd-9827-5350c3302f00')
      .then(() => {
        setIsAffiliate(true);
      })
      .catch(() => {
        setIsAffiliate(false);
      });
  }, [user?.affiliate.type]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      if (!user.termUser && isAffiliate) {
        const now = new Date();
        const formattedDate = format(now, 'MMMM dd, yyyy');
        setEffectiveDate(formattedDate);
        setShow(true);
      }
    }
  }, [isAffiliate, name, user]);

  const handleClose = useCallback(() => {
    setShow(false);
    setChecked(false);
    setDateSigned('');
    history.push(`${process.env.PUBLIC_URL}/lessons/welcome`);
  }, [history]);

  const handleClickResponse = useCallback(async () => {
    const now = new Date();
    setDateSigned(format(now, 'MM/dd/yyyy HH:mm:ss'));

    const formData = {
      name: user.name,
      ip,
      date_signed: now,
    };

    const response = await api.post('terms-users', formData);

    updateUser({
      ...user,
      termUser: response.data,
    });
  }, [ip, updateUser, user]);

  return (
    <TermsContext.Provider value={{}}>
      {children}
      <Modal show={show} size="xl" className="modal-link" centered>
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        />
        <button
          type="button"
          className="btn-logout"
          onClick={() => {
            signOut();
            setShow(false);
          }}
        >
          Logout
        </button>
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pt-4">
          <div className="mt-4 overflow-hidden w-100 px-sm-4">
            <Modal.Title className="pr-3">
              <h2 className="h5 text-center">
                AutoAffiliate Affiliate Agreement
              </h2>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-0 px-3 px-lg-5">
          <div className="bg-content px-3 py-4 pt-lg-2 px-lg-5 pb-lg-5">
            <div className="text-center text-lg-right mb-2">
              <p className="py-2 px-4 font-weight-bold scroll-text mb-0">
                Scroll down to the bottom to sign the contract.
                <Lottie
                  options={{
                    animationData: scrollDown,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={26}
                  width={26}
                />
              </p>
            </div>
            <div ref={scroll} className="content">
              <h3 className="mb-4">Effective Date: {effectiveDate}</h3>
              <h4>Introduction</h4>
              <p className="mb-3">
                Welcome to the AutoAffiliate Affiliate Program! This Agreement
                sets forth the terms under which you, as an independent
                Affiliate, may participate in our Program. Here, you have the
                opportunity to earn commissions by promoting and selling
                AutoAffiliate’s innovative products and services. This Agreement
                is designed to ensure a mutually beneficial relationship while
                adhering to the highest standards of compliance and ethics.
              </p>
              <p className="mb-3">
                Your decision to join and participate in the AutoAffiliate
                Affiliate Program signifies not only your acceptance of this
                Agreement but also your commitment to uphold our Terms of
                Service and all applicable federal and state regulations,
                including those enforced by the Federal Trade Commission (FTC).
                It’s crucial to understand that your role as an Affiliate is to
                act as an independent promoter of AutoAffiliate’s offerings, and
                this Agreement is structured to clarify that relationship, your
                responsibilities, and the framework within which you operate.
              </p>
              <p>
                Please read this Agreement carefully. By selecting “I Accept the
                Terms and Conditions” during your application process, you agree
                to adhere to its terms and affirm that you fully understand your
                rights and obligations herein. This Agreement is binding and
                governs your participation in our Program.
              </p>
              <h4>Agreement Acceptance</h4>
              <p>
                By choosing “I Accept the Terms and Conditions” during your
                application process, you are entering into a legally binding
                agreement with AutoAffiliate. This action signifies your
                complete understanding of and agreement to the terms outlined in
                this document. It is your responsibility to review this
                Agreement in its entirety and ensure you understand your
                obligations and the nature of your relationship with
                AutoAffiliate as an independent Affiliate.
              </p>
              <h4>Program Overview</h4>
              <p>
                <span>1. Opportunity for Earnings:</span> As an AutoAffiliate
                Affiliate, you have the opportunity to earn commissions by
                effectively promoting and selling AutoAffiliate’s diverse range
                of products and services. Your success as an Affiliate is
                contingent upon your marketing efforts and adherence to the
                guidelines and strategies provided by AutoAffiliate.
              </p>
              <p>
                <span>2. Application and Approval:</span> Your participation in
                the AutoAffiliate Affiliate Program is subject to a review and
                approval process. AutoAffiliate reserves the exclusive right to
                approve or deny any application based on our assessment of your
                suitability for the Program. This decision is at our sole
                discretion and may be based on various criteria, including but
                not limited to your marketing strategies, audience reach, and
                compliance with legal and ethical standards.
              </p>
              <p>
                <span>3. Definition of Referral:</span> Within the context of
                this Agreement, a “Referral” is defined as a new customer who
                purchases AutoAffiliate’s products or services through your
                unique affiliate link. It is essential that Referrals are
                acquired in adherence to our ethical guidelines and marketing
                practices, as detailed further in this Agreement.
              </p>
              <h4>Third-Party Offers and Liability</h4>
              <p>
                <span>1. Promotion of Third-Party Offers:</span> As part of the
                AutoAffiliate Affiliate Program, you may have the opportunity to
                promote offers from third-party companies. These offers are
                separate from AutoAffiliate and are governed by their respective
                terms and conditions. It’s important to understand that
                AutoAffiliate provides these opportunities for promotion as a
                courtesy and does not endorse, control, or assume liability for
                these third-party offers.
              </p>
              <p>
                <span>2. Affiliate Compensation for Third-Party Offers:</span>{' '}
                Any compensation you earn from promoting third-party offers is a
                matter solely between you and the respective third-party
                company. AutoAffiliate is not responsible for and shall not be
                involved in the compensation process for these offers. Your
                participation in promoting third-party offers indicates your
                agreement to this arrangement and understanding of where
                responsibility for compensation lies.
              </p>
              <p>
                <span>3. Disclaimer of Liability:</span> AutoAffiliate disclaims
                any liability for claims, losses, or damages arising from your
                promotion of third-party offers. Your decision to promote these
                offers should be based on a thorough independent assessment of
                their suitability and legitimacy. AutoAffiliate is not liable
                for any issues, including payment disputes, that arise between
                you and third-party companies.
              </p>
              <p>
                <span>4. No Implied Partnership or Agency:</span> Your promotion
                of third-party offers does not establish a partnership, joint
                venture, agency, or employment relationship between you and
                AutoAffiliate or between you and any third-party companies. You
                are acting as an independent contractor in all your promotional
                activities.
              </p>
              <h4>Affiliate Responsibilities</h4>
              <p>
                <span>1. Adherence to Laws and Regulations:</span> As an
                Affiliate, it's imperative that you comply with all applicable
                laws, regulations, and industry standards, including those
                related to advertising, consumer protection, and spam. Your
                promotional methods must be ethical, transparent, and devoid of
                deceptive practices.
              </p>
              <p>
                <span>2. Use of Authorized Promotional Materials:</span> When
                promoting AutoAffiliate products and services, you must use the
                marketing materials, referral links, and other resources
                provided by AutoAffiliate. Altering or misrepresenting these
                materials is strictly prohibited and may result in termination
                of your Affiliate status.
              </p>
              <p>
                <span>3. Accurate Representation:</span> All information you
                provide to AutoAffiliate and to potential Referrals must be
                accurate, complete, and truthful. You are required to promptly
                update any changes to your contact or other relevant
                information.
              </p>
              <p>
                <span>4. Non-Exclusivity:</span> This Agreement does not grant
                you exclusive rights to represent AutoAffiliate's products and
                services. AutoAffiliate reserves the right to engage with other
                affiliates and promote its offerings through various channels.
              </p>
              <p>
                <span>5. Independent Contractor Status:</span> You are an
                independent contractor and not an employee, agent, or
                representative of AutoAffiliate. You have no authority to bind
                AutoAffiliate in any respect. Your status as an independent
                contractor should be clearly communicated in your promotions.
              </p>
              <h4>Commission Structure</h4>
              <p>
                <span>1. Commission Rates and Tiers:</span> AutoAffiliate offers
                a tiered commission structure, where you can earn between 30% to
                60% commission on sales generated through your referral links.
                The specific commission rate depends on various factors,
                including the type of product or service sold and your level of
                participation in special promotions or tiers within the Program.
              </p>
              <p>
                <span>2. Eligibility for Commissions:</span> Commissions are
                only earned on sales that result in a successful transaction for
                AutoAffiliate products or services and are subject to the
                customer's retention beyond the refund period. AutoAffiliate
                reserves the right to review and validate all sales before
                approving commission payments.
              </p>
              <p>
                <span>3. Payment of Commissions:</span> Commissions are paid
                monthly, provided your earnings meet the minimum threshold set
                by AutoAffiliate. The payment is subject to a 45-day clearance
                period from the date of each sale to account for cancellations
                or refunds. Details regarding payment methods and any applicable
                fees will be communicated separately.
              </p>
              <p>
                <span>4. Referral Tracking:</span> AutoAffiliate will use
                advanced tracking mechanisms to accurately track sales generated
                through your referral links. It is crucial to the integrity of
                the Program that you do not manipulate or disrupt the tracking
                process.
              </p>
              <p>
                <span>5. Tax Responsibility:</span> You are solely responsible
                for all tax liabilities arising from your receipt of commissions
                and should report your earnings in accordance with relevant tax
                laws.
              </p>
              <h4>Commission Clawback Due to Refunds</h4>
              <p>
                <span>1. Clawback Policy:</span> In the event that a sale of an
                AutoAffiliate product or service results in a refund,
                chargeback, or is otherwise reversed, any commission paid to the
                Affiliate for that sale will be subject to clawback. This means
                that the Affiliate will be required to return any commission
                earned on a sale that does not ultimately result in a successful
                transaction.
              </p>
              <p>
                <span>2. Deduction from Future Commissions:</span> Typically,
                the clawback amount will be deducted from future commission
                payments owed to the Affiliate. If there are no pending
                commission payments or if the clawback amount exceeds the
                pending commissions, AutoAffiliate may require direct repayment
                from the Affiliate.
              </p>
              <p>
                <span>3. Notification of Clawback:</span> AutoAffiliate will
                notify the Affiliate of any clawback instances and provide
                details of the affected transaction(s). The Affiliate will have
                a specified period to respond to or dispute the clawback notice.
              </p>
              <p>
                <span>4. Dispute Resolution:</span> In case of a dispute by the
                Affiliate regarding a clawback, a review process will be
                initiated by AutoAffiliate. The decision made by AutoAffiliate
                at the conclusion of the review process will be final.
              </p>
              <p>
                <span>5. Acknowledgment of Clawback Terms:</span> By
                participating in the AutoAffiliate Affiliate Program, the
                Affiliate acknowledges and agrees to this clawback policy as a
                condition of their participation.
              </p>
              <h4>Founders Club Program</h4>
              <p>
                <span>1. Introduction and Eligibility:</span> The Founders Club
                Program is an exclusive initiative designed to reward proactive
                members of the AutoAffiliate community. Eligibility for this
                program is now granted through the purchase of the Founders Club
                program, which includes new added benefits. Previous members of
                the legacy Founders Club program will continue to be respected,
                provided they maintain their subscription to the third-party
                offer and sign up for the new updated AutoAffiliate Affiliate
                Program subscription.
              </p>
              <p>
                <span>2. Lead Distribution and Quality Assurance:</span> Members
                of the Founders Club will benefit from a systematic distribution
                of valuable leads. The lead distribution process is structured
                to ensure fairness and efficiency, providing equitable
                opportunities for all participants. We prioritize high-quality,
                relevant leads and adhere to a standard processing period to
                maintain these standards, which may result in a brief waiting
                period for lead distribution.
              </p>
              <p>
                <span>3. Maintaining Eligibility and Founder Status:</span> To
                maintain eligibility for the free leads giveaway and other
                benefits, members must keep an active subscription to the
                updated AutoAffiliate Affiliate Program. Legacy Founders must
                also maintain their subscription to the third-party offer. If
                you choose not to participate in the leads giveaway or are
                unable to maintain the required subscriptions, your Founder
                status – indicative of your early commitment and proactive
                involvement – will be retained.
              </p>
              <p>
                <span>4. Transparency and Fairness:</span> We are committed to
                transparency and fairness in the operations of the Founders
                Club. While not every operational detail may be disclosed, our
                aim is to ensure that each member benefits equitably from their
                participation.
              </p>
              <p>
                <span>5. Detailed Policy Documentation:</span> A comprehensive
                policy document, available to all Founders Club members,
                outlines the operational details, the lead distribution process,
                and the journey of participants in the program. This document
                serves as a guide and reference for all aspects of the Founders
                Club experience.
              </p>
              <p>
                <span>6. Swift Action for Eligibility:</span> To become a member
                of the Founders Club, new members must purchase the Founders
                Club program within 30 days of joining AutoAffiliate. This
                condition is set to reward and encourage decisive action among
                our members.
              </p>
              <p>
                <span>7. Non-Guarantee of Membership:</span> Membership in the
                Founders Club is a privilege and not a right. AutoAffiliate does
                not guarantee admission to any Affiliate and retains sole
                discretion over the selection and retention of members in the
                Founders Club. This ensures the integrity and exclusivity of the
                program, aligning with our commitment to reward exceptional
                performance and engagement.
              </p>
              <h4>Intellectual Property</h4>
              <p>
                <span>1. Ownership of Intellectual Property:</span> All
                intellectual property related to AutoAffiliate, including
                trademarks, service marks, trade names, logos, and copyrights,
                remains the sole property of AutoAffiliate. This Agreement does
                not transfer any intellectual property rights to you.
              </p>
              <p>
                <span>2. Limited License for Promotion:</span> AutoAffiliate
                grants you a limited, non-exclusive, and revocable license to
                use its intellectual property solely for the purpose of
                promoting its products and services within the guidelines set
                forth in this Agreement.
              </p>
              <p>
                <span>3. Proper Use of Intellectual Property:</span> You must
                use AutoAffiliate's intellectual property in a manner that
                maintains the integrity of the brand and adheres to any
                guidelines provided. Misuse or unauthorized use of
                AutoAffiliate's intellectual property is grounds for immediate
                termination of this Agreement.
              </p>
              <h4>Confidentiality</h4>
              <p>
                <span>1. Definition of Confidential Information:</span>{' '}
                Throughout your participation in the Affiliate Program, you may
                be exposed to sensitive and proprietary information of
                AutoAffiliate, including but not limited to sales data,
                marketing strategies, and customer information.
              </p>
              <p>
                <span>2. Obligation of Confidentiality:</span> You agree to
                maintain the confidentiality of all such information and to use
                it solely for the purpose of fulfilling your obligations under
                this Agreement. Unauthorized disclosure of confidential
                information is prohibited and may result in termination of your
                Affiliate status and legal action.
              </p>
              <h4>Advertising Rules</h4>
              <p>
                <span>1. Compliance with Laws and Guidelines:</span> All your
                advertising activities must comply with applicable laws and
                regulations, including FTC guidelines on advertising, consumer
                protection laws, and any other relevant standards. This includes
                truthful representations, proper disclosure of your affiliate
                status, and avoidance of misleading claims.
              </p>
              <p>
                <span>2. Mandatory Disclosures:</span> In all promotional
                content, you must clearly and prominently disclose your
                relationship with AutoAffiliate, stating that you are an
                independent Affiliate and that you may receive compensation for
                referrals. This disclosure must be made in accordance with FTC
                guidelines.
              </p>
              <p>
                <span>3. Guidelines for Social Media Promotion:</span> When
                promoting AutoAffiliate on social media, ensure that each post
                complies with the platform's advertising rules and includes
                necessary disclosures (e.g., #ad or “Sponsored”). Utilize
                platform-specific tools for sponsored content where available.
              </p>
              <p>
                <span>4. Restrictions on Income Claims:</span> You are
                prohibited from making any guarantees or unrealistic claims
                regarding potential income or earnings through the AutoAffiliate
                Program. Any mention of your earnings must be truthful,
                accompanied by disclaimers, and in line with FTC guidelines.
              </p>
              <p>
                <span>5. Intellectual Property in Advertising:</span> Use of
                AutoAffiliate's intellectual property in your advertisements
                must be pre-approved in writing and conform to brand guidelines.
                Misuse of trademarks or copyrighted material is not permitted.
              </p>
              <p>
                <span>6. Monitoring and Compliance:</span> AutoAffiliate
                reserves the right to monitor your advertising activities for
                compliance. Non-compliance may necessitate modifications to your
                materials and could result in termination of your affiliate
                status.
              </p>
              <p>
                <span>7. Clear and Conspicuous Disclosures:</span>All
                disclosures required by FTC guidelines, including but not
                limited to the affiliate's relationship with AutoAffiliate and
                any compensation received, must be clear and conspicuous in all
                promotional content. Disclosures should be placed close to the
                relevant claims, be easy to notice and read, and not be hidden
                or obscured by other content.
              </p>
              <p>
                <span>
                  8. Compliance with International E-commerce Standards:
                </span>
                Affiliates promoting AutoAffiliate’s products internationally
                must comply with all applicable laws and guidelines, including
                but not limited to the OECD guidelines for consumer protection
                in electronic commerce. This includes providing accurate and
                clear information about the products and services, the terms of
                the sale, and any consumer protections available.
              </p>

              <p>
                <span>9. Prohibited Practices:</span>Affiliates are strictly
                prohibited from making false or misleading claims regarding
                potential earnings, product performance, or any other aspect of
                AutoAffiliate's products and services. Any such deceptive
                practices will result in immediate termination of the affiliate
                agreement and may lead to legal action.
              </p>

              <h4>Termination</h4>
              <p>
                <span>1. Termination for Convenience:</span> Either party may
                terminate this Agreement at any time for any reason, with
                written notice to the other party. Upon termination, you must
                immediately cease all use of AutoAffiliate’s intellectual
                property and remove all promotional materials.
              </p>
              <p>
                <span>2. Termination for Cause:</span> AutoAffiliate reserves
                the right to terminate this Agreement immediately and without
                notice in the event of any breach by you, fraudulent activity,
                or any action that harms AutoAffiliate’s reputation or
                interests.
              </p>
              <p>
                <span>3. Effects of Termination:</span> Upon termination, all
                rights granted to you under this Agreement cease. You will
                forfeit any unpaid commissions as of the termination date and
                must immediately discontinue use of all AutoAffiliate materials
                and intellectual property.
              </p>
              <h4>Disclaimer of Warranties and Limitation of Liability</h4>
              <p>
                <span>1. Disclaimer of Warranties:</span> AutoAffiliate provides
                the Affiliate Program, including all related products and
                services, on an “as is” and “as available” basis. AutoAffiliate
                expressly disclaims all warranties of any kind, whether express
                or implied, including, but not limited to, the implied
                warranties of merchantability, fitness for a particular purpose,
                and non-infringement. AutoAffiliate makes no warranty that the
                Affiliate Program will meet your requirements, or that the
                Program will be uninterrupted, timely, secure, or error-free;
                nor does AutoAffiliate make any warranty as to the results that
                may be obtained from the use of the Program or as to the
                accuracy or reliability of any information obtained through the
                Program.
              </p>
              <p>
                <span>2. Limitation of Liability:</span> AutoAffiliate shall not
                be liable for any indirect, incidental, special, consequential,
                or exemplary damages, including but not limited to damages for
                loss of profits, goodwill, use, data, or other intangible losses
                (even if AutoAffiliate has been advised of the possibility of
                such damages), resulting from: (i) the use or the inability to
                use the Affiliate Program; (ii) the cost of procurement of
                substitute goods and services resulting from any goods, data,
                information, or services purchased or obtained or messages
                received or transactions entered into through or from the
                Program; (iii) unauthorized access to or alteration of your
                transmissions or data; (iv) statements or conduct of any third
                party on the Program; or (v) any other matter relating to the
                Program.
              </p>
              <p>
                <span>3. Class Action Waiver:</span> You agree that any claims
                or controversies arising out of or related to this Agreement or
                your participation in the Affiliate Program shall be brought in
                your individual capacity, and not as a plaintiff or class member
                in any purported class, collective, consolidated, or
                representative proceeding. AutoAffiliate and you agree that each
                may bring claims against the other only in your or its
                individual capacity and not as a plaintiff or class member in
                any purported class or representative action.
              </p>
              <h4>Governing Law and Dispute Resolution</h4>
              <p>
                <span>1. Applicable Law:</span> This Agreement shall be governed
                by and construed in accordance with the laws of the State of
                Wyoming, without regard to its conflict of law principles. Any
                legal actions or proceedings arising out of this Agreement shall
                be brought exclusively in the courts of Wyoming, and both
                parties hereby consent to the jurisdiction of such courts.
              </p>
              <p>
                <span>2. Dispute Resolution:</span> In the event of a dispute
                arising under this Agreement, the parties agree to first attempt
                to resolve the issue through good faith negotiation. If
                negotiation fails, the dispute shall be submitted to mediation
                or, if necessary, arbitration, in accordance with the rules of a
                mutually agreed upon mediation or arbitration entity. The
                prevailing party in any such dispute resolution process shall be
                entitled to recover its reasonable attorney's fees and costs.
              </p>
              <h4>Communication Consent</h4>
              <p className="mb-3">
                By agreeing to these terms, I hereby give AutoAffiliate consent
                to contact me through text messages, automated calls, emails,
                and other forms of electronic communication for purposes
                including but not limited to marketing, notifications, updates,
                and customer service communications. I understand that standard
                data and message rates may apply for text messages. I
                acknowledge that my consent is not a condition of any purchase.
              </p>
              <p className="mb-3">
                I understand that I have the right to opt-out of receiving
                further communications at any time. To unsubscribe or change my
                communication preferences, I can follow the instructions
                provided in the communications sent to me or contact
                AutoAffiliate at support@autoaffiliate.ai.
              </p>
              <p>
                AutoAffiliate respects and prioritizes the privacy and security
                of my personal data. My contact information will be used in
                accordance with AutoAffiliate’s Privacy Policy and will not be
                shared with third parties without my explicit consent.
              </p>
              <h4>Miscellaneous</h4>
              <p>
                <span>1. Entire Agreement:</span> This Agreement constitutes the
                entire understanding between AutoAffiliate and the Affiliate
                regarding the subject matter herein and supersedes all prior
                agreements and understandings, whether written or oral.
              </p>
              <p>
                <span>2. Amendments:</span> AutoAffiliate reserves the right to
                modify or update the terms and conditions of this Agreement at
                any time. Affiliates will be notified of any changes through the
                contact information provided during their application process or
                via the Affiliate dashboard. Continued participation in the
                AutoAffiliate Affiliate Program after any such modifications
                will constitute your consent to such changes. It is the
                responsibility of the Affiliate to regularly review the
                Agreement and stay informed of any updates.
              </p>
              <p>
                <span>3. Assignment:</span> The Affiliate may not assign or
                transfer any of their rights or obligations under this Agreement
                without prior written consent from AutoAffiliate. However,
                AutoAffiliate may assign this Agreement without restriction.
              </p>
              <p>
                <span>4. Severability:</span> If any provision of this Agreement
                is found to be invalid or unenforceable, the remaining
                provisions will remain in full force and effect.
              </p>
              <p>
                <span>5. Waiver:</span> No waiver of any provision of this
                Agreement shall be deemed a further or continuing waiver of such
                provision or any other provision.
              </p>
              <p>
                <span>6. Independent Contractor Relationship:</span> Nothing in
                this Agreement shall be construed as creating an
                employer-employee relationship, partnership, or joint venture
                between the parties. The Affiliate is an independent contractor.
              </p>
              <p>
                <span>7. Notices:</span> All notices and communications required
                or permitted under this Agreement should be in writing and sent
                to the respective parties at the addresses given at the outset
                of this Agreement or to such other address as either party may
                later provide by notice.
              </p>
              <p>
                <span>8. Force Majeure:</span> Neither party shall be liable for
                any failure or delay in performance due to circumstances beyond
                their reasonable control, including acts of God, natural
                disasters, terrorism, government actions, or labor disputes.
              </p>
              <p>
                <span>9. Counterparts and Electronic Signatures:</span> This
                Agreement may be executed in counterparts, each of which shall
                be deemed an original. Electronic signatures shall be considered
                as valid and binding as original signatures.
              </p>
              <p>
                <span>10. Headings:</span> Headings in this Agreement are for
                convenience only and do not affect the interpretation of any
                provision.
              </p>
              <p>
                <span>11. Survival:</span> Provisions of this Agreement that by
                their nature should survive termination will remain in effect
                after termination or expiration of this Agreement.
              </p>
              <p className="my-4">
                IN WITNESS WHEREOF, the parties have executed this AutoAffiliate
                Affiliate Agreement as of the Effective Date.
              </p>
              <p className="mb-4">
                Copyright 2024 - SmartTech Labs, Inc, dba AutoAffiliate - All
                Rights Reserved
              </p>
              <div className="scroll-click-div d-flex justify-content-center justify-content-sm-start mt-5 mb-0">
                <div className="">
                  <Lottie
                    options={{
                      animationData: ArrowDown,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={30}
                    width={30}
                  />
                </div>
                <p className="font-weight-bold scroll-click">
                  Click here to e-sign and continue.
                </p>
              </div>
              <label htmlFor="check" className="d-flex ml-2">
                <InputCheckbox
                  name="check"
                  options={[
                    {
                      labelHtml: true,
                      label: `I, ${name}, understand and agree to the terms and conditions of the affiliate agreement`,
                      value: `I, ${name}, understand and agree to the terms and conditions of the affiliate agreement`,
                      selected: checked,
                    },
                  ]}
                  onChange={() => setChecked(!checked)}
                />
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 pt-0 pb-3 my-4">
          <div className="d-flex justify-content-center justify-content-lg-end w-100">
            {show && dateSigned ? (
              <>
                {/* <PDFDownloadLink
                  document={
                    (
                      <GeneratePDF
                        name={user.name}
                        dateSigned={dateSigned}
                        ip={ip}
                        effectiveDate={effectiveDate}
                      />
                    ) as React.ReactElement
                  }
                  fileName="autoaffiliate-affiliate-agreement.pdf"
                  className="btn btn-download"
                >
                  <VscDesktopDownload
                    size={24}
                    color="#303030"
                    className="mr-2"
                  />
                  <span>Download document</span>
                </PDFDownloadLink> */}
                <button
                  type="button"
                  className="btn btn-download ml-3"
                  onClick={handleClose}
                >
                  Close
                </button>
              </>
            ) : (
              <div
                title="Please read the terms to the end and check the box to proceed"
                className={`${!checked && 'cursor-allowed'} d-flex`}
              >
                <button
                  type="button"
                  className={`${
                    checked ? 'opacity-100' : 'opacity-25 pe-none '
                  } btn btn-accept`}
                  onClick={() => handleClickResponse()}
                >
                  Accept
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </TermsContext.Provider>
  );
};

export function useTerms(): TermsContextData {
  const context = useContext(TermsContext);

  if (!context) {
    throw new Error('useTerms must be used within an TermsProvider');
  }

  return context;
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { AxiosResponse } from 'axios';

import api from '~/services/api';

import { Container, Modal, Loading } from './styles';

import safe from '~/assets/defaults/safe_secure.svg';
import money from '~/assets/defaults/money_back.svg';
import privacy from '~/assets/defaults/privacy_proteced.svg';
import check from '~/assets/icons/check-payment.svg';
import checkPlus from '~/assets/icons/check-plus.svg';
import swalError from '~/utils/swalError';
import { useAuth } from '~/hooks/Auth';
import CreditCard, { ICreditCard } from '../CreditCard';

export interface IOption {
  price: number;
  selected?: boolean;
  name?: string;
  nameSummary?: string;
  secondary?: boolean;
  src?: string;
  installments?: number;
  period?: number;
  type?: 'lifetime' | 'subscription';
  interval?: 'day' | 'month' | 'week' | 'year';
  productSlug?: string;
  unSelectable?: boolean;
  trial_period_days?: number;
}

interface IPayment {
  show: boolean;
  onHide(): void;
  productName: string;
  btnText: string;
  onSuccessfullyPaid(data: any): void;
  productSlug?: string;
  options: IOption[];
}

const Payment: React.FC<IPayment> = ({
  show,
  onHide,
  productName,
  btnText,
  onSuccessfullyPaid,
  productSlug,
  options: optionsData,
}) => {
  const { user } = useAuth();
  const [step, setStep] = useState(1);
  const [productId, setProductId] = useState('');
  const [loading, setLoading] = useState(false);
  const [creditCardSelected, setCreditCardSelected] = useState(
    {} as ICreditCard
  );
  const [options, setOptions] = useState<IOption[]>([]);
  const [optionsSecondary, setOptionsSecondary] = useState<IOption[]>([]);
  const [optionSelected, setOptionSelected] = useState<IOption | undefined>(
    undefined
  );
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [value, setValue] = useState<number[]>([]);

  useEffect(() => {
    const optionSelectedData = optionsData.find((option) => option.selected);
    if (optionSelectedData) {
      setOptionSelected(optionSelectedData);
    }

    setOptions(optionsData.filter((option) => !option.secondary));
    setOptionsSecondary(optionsData.filter((option) => option.secondary));
  }, [optionsData]);

  useEffect(() => {
    api.get(`products/${productSlug}`).then((response) => {
      setProductId(response.data.id);
    });
  }, [productSlug, show]);

  useEffect(() => {
    const newPrices = optionsSecondary
      .filter((optionData) => optionData.selected)
      .map((optionData) => optionData.price);

    setTotalPrice(newPrices.reduce((total, price) => total + price, 0));
  }, [optionsSecondary]);

  const handleSetStep = useCallback(() => {
    switch (step) {
      case 1:
        setStep(step + 1);

        break;

      default:
        setStep(1);
        break;
    }
  }, [step]);

  const handleClickConfirmPayment = useCallback(async () => {
    try {
      const optionsSelected = [
        optionSelected,
        ...optionsSecondary.filter((option) => option.selected),
      ];
      if (optionsSelected.length > 0) {
        setLoading(true);
        const responsesData = await new Promise<any>((resolve) => {
          const responses: AxiosResponse[] = [];
          optionsSelected.forEach(async (option, index) => {
            const responseProduct = await api.get(
              `products/${option?.productSlug || productSlug}`
            );
            const response = await api.post(
              `payments/credit-cards/${user.id}`,
              {
                payment_method: creditCardSelected.id,
                price: option?.price,
                product_id: responseProduct.data.id,
                brand: creditCardSelected.brand,
                number: creditCardSelected.number,
                period: option?.period,
                installments: option?.installments,
                type: option?.type,
                interval: option?.interval,
                trial_period_days: option?.trial_period_days,
              }
            );
            responses.push(response.data);
            if (optionsSelected.length === index + 1) {
              resolve(responses);
            }
          });
        });
        onSuccessfullyPaid(responsesData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swalError({
        message:
          'Looks like what you were trying to do didn’t work, please try again.',
        textButton: 'Try Again',
      });
    }
  }, [
    optionSelected,
    optionsSecondary,
    onSuccessfullyPaid,
    productSlug,
    user.id,
    creditCardSelected.id,
    creditCardSelected.brand,
    creditCardSelected.number,
  ]);

  const handleSelectProduct = useCallback(
    (option: IOption) => {
      const newOptions = options.map((optionData) => {
        let { selected } = optionData;
        if (optionData.name === option.name) {
          selected =
            optionData.src === option.src &&
            (option.unSelectable || !optionData.selected);
        }

        return {
          ...optionData,
          selected,
        };
      });

      const hasOptionSelected = newOptions.find(
        (optionData) => optionData.selected
      );

      if (!hasOptionSelected) {
        setOptionsSecondary((state) =>
          state.map((optionData) => ({ ...optionData, selected: false }))
        );
      }

      setOptions(newOptions);

      if (!option.secondary) {
        setOptionSelected((state) =>
          state && state.src === option.src && !option.unSelectable
            ? undefined
            : option
        );
      }
    },
    [options]
  );

  const handleSelectSecondaryProduct = useCallback(
    (option: IOption) => {
      const optionIndex = optionsSecondary.findIndex(
        (optionData) => optionData.src === option.src
      );

      const newOptions = optionsSecondary.map((optionData, index) => {
        let { selected } = optionData;
        if (optionData.name === option.name) {
          selected =
            optionData.src === option.src &&
            (option.unSelectable || !optionData.selected);
        }

        if (optionsSecondary[optionIndex].selected && index > optionIndex) {
          selected = false;
        }

        return {
          ...optionData,
          selected,
        };
      });

      setOptionsSecondary(newOptions);

      const newPrices = newOptions
        .filter((optionData) => optionData.selected)
        .map((optionData) => optionData.price);

      setTotalPrice(newPrices.reduce((total, price) => total + price, 0));
    },

    [optionsSecondary]
  );

  const handleChangeCardSelected = useCallback(
    (creditCardData: ICreditCard) => {
      setCreditCardSelected(creditCardData);
      setStep(2);
    },
    []
  );

  return (
    <Container>
      <Modal
        className="modal-sales-funnel px-0"
        centered
        size="xl"
        show={show}
        onHide={onHide}
      >
        <Modal.Header className="d-block border-0 pb-0 pt-4 px-4">
          <div className="w-100 text-right">
            <button
              type="button"
              className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
              onClick={onHide}
            >
              <IoMdClose color="#151517" size={24} />
            </button>
          </div>
          <div className="container">
            <div className="row mb-5 pt-2 justify-content-center">
              <div
                className={`${step === 1 ? 'selected-step' : 'step'} ${
                  step > 1 ? 'active-step cursor-pointer' : ''
                } px-0 col-3 col-lg-2 text-center`}
                onClick={() => setStep(1)}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">1</span>
                </div>
                <p>Payment Method</p>
              </div>
              <div className="col-1 pt-2">
                <hr className={step > 1 ? 'active-hr' : ''} />
              </div>
              <div
                className={`${
                  step === 2 ? 'selected-step cursor-pointer' : 'step'
                } px-0 col-3 col-lg-2 text-center`}
                onClick={() => setStep(2)}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">2</span>
                </div>
                <p>Confirm</p>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="container py-0 px-4 px-lg-5">
          <CreditCard
            type="sale"
            onChangeCreditCardSelected={handleChangeCardSelected}
            className={step === 1 ? 'd-block' : 'd-none'}
          />
          <div
            className={`row justify-content-center items ${
              step === 2 ? '' : 'd-none'
            }`}
          >
            <div className="col-xl-10 mb-5">
              <div className="row">
                <div className="col-10 mb-1">
                  <h3 className="font-weight-600">Item</h3>
                </div>
                <div className="col-2 d-flex justify-content-end mb-1">
                  <h3 className="font-weight-600 text-end">Price</h3>
                </div>

                <div className="col-10 mb-4">
                  <span
                    className="font-weight-500"
                    dangerouslySetInnerHTML={{ __html: productName }}
                  />
                </div>
                <div
                  className={`col-2 justify-content-end
                   d-flex mb-4`}
                >
                  <span className="font-weight-500 text-end price">
                    ${(optionSelected?.price || 0).toFixed(2)}
                  </span>
                  {/* {(downsellPage ||
                    (blackFridayPage && optionSelected?.period)) && (
                    <span className="months">
                      in {optionSelected?.period} month
                      {optionSelected?.period !== undefined &&
                        optionSelected?.period > 1 &&
                        's'}{' '}
                      ${(optionSelected?.price || 0).toFixed(2)}
                    </span>
                  )} */}
                </div>

                {options.map((option, index) => (
                  <button
                    key={index.toString()}
                    type="button"
                    className={`${
                      option.selected
                        ? 'opacity-100'
                        : `${!option.secondary ? 'opacity-50 grayscale ' : ''} `
                    } col-12 border-0 px-2 mb-3 bg-transparent position-relative`}
                    onClick={() => {
                      handleSelectProduct(option);
                      setValue([option.price]);
                    }}
                  >
                    {option.selected && (
                      <img src={check} alt="Check" className="check-position" />
                    )}
                    {!option.selected && option.secondary && (
                      <img
                        src={checkPlus}
                        alt="Check"
                        className="check-position"
                      />
                    )}
                    <img
                      src={option.src}
                      alt="checkout"
                      className={`bd-blue w-100 `}
                    />
                  </button>
                ))}

                {optionSelected &&
                  optionsSecondary.map((option, index) => (
                    <Fragment key={index.toString()}>
                      {(index === 0 ||
                        (optionsSecondary[index - 1] &&
                          optionsSecondary[index - 1].selected)) && (
                        <button
                          type="button"
                          className={`${
                            option.selected
                              ? 'opacity-100'
                              : `${
                                  !option.secondary
                                    ? 'opacity-50 grayscale '
                                    : ''
                                } `
                          } col-12 border-0 ${
                            !option.secondary ? 'px-0' : 'px-2 mb-3'
                          }   bg-transparent position-relative`}
                          onClick={() => {
                            handleSelectSecondaryProduct(option);
                          }}
                        >
                          {option.selected && (
                            <img
                              src={check}
                              alt="Check"
                              className="check-position"
                            />
                          )}
                          {!option.selected && option.secondary && (
                            <img
                              src={checkPlus}
                              alt="Check"
                              className="check-position"
                            />
                          )}
                          <img
                            src={option.src}
                            alt="checkout"
                            className={`${
                              option.secondary
                                ? `${option.selected ? 'bd-blue' : 'bd-dashed'}`
                                : ''
                            } w-100 `}
                          />
                        </button>
                      )}
                    </Fragment>
                  ))}
                <div className="col-10 mt-4 mb-3">
                  <h3 className="font-weight-600">Item</h3>
                </div>
                <div className="col-2 d-flex justify-content-end mt-4 mb-3">
                  <h3 className="font-weight-600 text-end">Price</h3>
                </div>

                <div className="col-10 mb-3">
                  <span
                    className="font-weight-500"
                    dangerouslySetInnerHTML={{ __html: productName }}
                  />
                </div>
                <div className="col-2 justify-content-end d-flex mb-3">
                  <span className="font-weight-500 text-end price">
                    ${(optionSelected?.price || 0).toFixed(2)}
                    {optionSelected?.interval === 'month' && '/Mo'}
                  </span>
                </div>
                {optionSelected &&
                  optionsSecondary.map((option, index) => (
                    <Fragment key={index.toString()}>
                      {optionsSecondary[0].selected &&
                        optionsSecondary[index] &&
                        optionsSecondary[index].selected && (
                          <>
                            <div className="col-10 mb-3">
                              <span className="font-weight-500">
                                {optionsSecondary[index].nameSummary}
                              </span>
                            </div>
                            <div className="col-2 justify-content-end d-flex mb-3">
                              <span className="font-weight-500 text-end price">
                                ${(option.price || 0).toFixed(2)}
                                {option.interval === 'month' && '/Mo'}
                              </span>
                            </div>
                          </>
                        )}
                    </Fragment>
                  ))}
                <hr className="opacity-100" />
                <div className="col-5 mb-1">
                  <h3 className="font-weight-600">Order Total:</h3>
                </div>
                <div className="col-7 d-flex justify-content-end mb-1">
                  <h3 className="font-weight-600 text-end total-color">
                    ${' '}
                    {user.affiliate.type.toLowerCase().includes('founder') &&
                    productName ===
                      'Affiliate Program Community Access & AI Tools' ? (
                      <>
                        {optionSelected
                          ? `${
                              user.affiliate.type === 'VIP Founder'
                                ? '0.00'
                                : totalPrice?.toFixed(2)
                            } Now - $47.00 in 45 days`
                          : 0}
                      </>
                    ) : (
                      <>
                        {optionSelected
                          ? (optionSelected.price + totalPrice)?.toFixed(2)
                          : 0}
                      </>
                    )}
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-10">
              <button
                type="button"
                className={`${loading && 'pe-none'} ${
                  !optionSelected ? 'opacity-50 pe-none' : ''
                } w-100 d-flex justify-content-center border-0 btn-black font-weight-600 text-white`}
                onClick={handleClickConfirmPayment}
              >
                {loading ? (
                  <span className="d-flex align-items-center font-weight-600 text-white">
                    <Loading className="d-block mr-3 spinner" />
                    Processing Payment, Please Hold...
                  </span>
                ) : (
                  btnText
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="container border-0">
          <div className="row w-100 justify-content-center">
            <div className="col-xl-10 my-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-sm-6 col-lg-4 text-center">
                  <img src={safe} alt="GUARANTEED SAFE & SECURE CHECKOUT" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center mt-4 mt-sm-0">
                  <img src={money} alt="MONEY-BACK GUARANTEE" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center my-4 my-lg-0">
                  <img src={privacy} alt="PRIVACY PROTECTED" />
                </div>
                <div className="col-12 terms mt-1 pb-5">
                  <h4 className="mb-1">
                    <b>Terms of Sale:</b>
                  </h4>
                  <p className="font-weight-200 mb-0">
                    Your purchase will appear on your bank statement under the
                    name "<b>SmartTech Labs, Inc.</b>" The geographic location
                    of your computer has been determined and recorded. All
                    attempts at fraud will be prosecuted. Information submitted
                    in conjunction with this order is handled within the
                    constraints of our privacy policy. Your name, phone, and
                    email address may be provided to 3rd parties completion of
                    this purchase. You agree to receive marketing emails from
                    our company. By purchasing you agree to allow us to send
                    information to you via phone and SMS messaging. Message &
                    Data rates may apply. Reply stop to be removed. We will
                    instantly send your login information to our online training
                    program once your purchase is completed. Please review our
                    terms and conditions for the 30 day MBG refund policy on
                    this purchase.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Payment;

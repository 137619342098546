import React, { useCallback, useEffect, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Card, Container, Flag, LinkCopy } from './styles';
import { useLanguage } from '~/hooks/Language';
import { useAuth } from '~/hooks/Auth';
import facebook from '~/assets/icons/facebook-icon.svg';
import twitter from '~/assets/icons/twitter-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-circle-icon.svg';
import testImage from '~/assets/defaults/test-image.png';

interface IEmail {
  id: string;
  subject: string;
  body: string;
}

interface ISocial {
  id: string;
  title: string;
  subtitle: string;
  body: string;
}

interface IImages {
  id: string;
  url: string;
}

const Swipe: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [copied, setCopied] = useState(false);
  const [linkSelected, setLinkSelected] = useState('');
  const [choiseShare, setChoiseShare] = useState('emails');
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [socials, setSocials] = useState<ISocial[]>([]);
  const [images, setImages] = useState<IImages[]>([]);

  useEffect(() => {
    setEmails([
      {
        id: '1',
        subject:
          'Unlock Your Affiliate Marketing Potential with AutoAffiliate! 1',
        body:
          "1 Hi [Name],\n\nAre you ready to revolutionize your affiliate marketing strategy? Join us at AutoAffiliate, where we empower our members with cutting-edge tools and exclusive offers to skyrocket their earnings.\n\nAs a member, you'll benefit from:\n\n• Access to high-converting affiliate offers\n• Competitive commission rates\n• Advanced marketing tools designed for maximum reach and impact\n\nDon't miss this opportunity to enhance your affiliate marketing journey. Sign up today and start turning your efforts into substantial earnings!\n\nWarm regards,\n[Your Name]",
      },
      {
        id: '2',
        subject:
          'Unlock Your Affiliate Marketing Potential with AutoAffiliate! 2',
        body:
          "2 Hi [Name],\n\nAre you ready to revolutionize your affiliate marketing strategy? Join us at AutoAffiliate, where we empower our members with cutting-edge tools and exclusive offers to skyrocket their earnings.\n\nAs a member, you'll benefit from:\n\n• Access to high-converting affiliate offers\n• Competitive commission rates\n• Advanced marketing tools designed for maximum reach and impact\n\nDon't miss this opportunity to enhance your affiliate marketing journey. Sign up today and start turning your efforts into substantial earnings!\n\nWarm regards,\n[Your Name]",
      },
      {
        id: '3',
        subject:
          'Unlock Your Affiliate Marketing Potential with AutoAffiliate! 3',
        body:
          "3 Hi [Name],\n\nAre you ready to revolutionize your affiliate marketing strategy? Join us at AutoAffiliate, where we empower our members with cutting-edge tools and exclusive offers to skyrocket their earnings.\n\nAs a member, you'll benefit from:\n\n• Access to high-converting affiliate offers\n• Competitive commission rates\n• Advanced marketing tools designed for maximum reach and impact\n\nDon't miss this opportunity to enhance your affiliate marketing journey. Sign up today and start turning your efforts into substantial earnings!\n\nWarm regards,\n[Your Name]",
      },
    ]);

    setSocials([
      {
        id: '1',
        title: 'Introduction to AutoAffiliate',
        subtitle:
          '🚀 Discover the Power of Automation in Affiliate Marketing with AutoAffiliate! 🚀',
        body:
          'Ever wondered how to make your affiliate marketing efforts more effective with less effort? Join us at AutoAffiliate, where we unlock the secrets to automating your success. Dive into a community dedicated to sharing, learning, and growing together in the world of affiliate marketing.\n\n👉 DM me to Join the revolution and start transforming your strategies today\n\n#AutoAffiliate #AffiliateMarketing\n#MarketingAutomation',
      },
      {
        id: '2',
        title: 'Success Story/Testimonial',
        subtitle:
          '💡 Real Results, Real Impact: My Journey with AutoAffiliate 💡',
        body:
          "Since joining AutoAffiliate, I've seen my affiliate marketing game change completely. With the tools and strategies shared here, I've managed to double my conversion rate in just a few months! This community is a goldmine for anyone serious about leveraging automation in their marketing efforts.\n\n🌟 Ready to write your own success story?\n\nDM me  to find out how with AutoAffiliate.\n\n#SuccessWithAutoAffiliate #MarketingSuccess\n#JoinUsNow",
      },
      {
        id: '3',
        title: 'Invitation to a Webinar/Event',
        subtitle: '🎉 Exclusive Event Alert for Affiliate Marketers! 🎉',
        body:
          "Want to learn how to automate your affiliate marketing for maximum efficiency and profit?\n\nJoin us at our next AutoAffiliate webinar where industry experts unveil cutting-edge strategies and tools that will revolutionize your approach.\n\n✅ DM me for the link and mark your calendar, don't miss out on this opportunity to elevate your affiliate marketing strategies.\n\n#AutoAffiliateWebinar #AffiliateGrowth\n#MarketingEvent",
      },
    ]);

    setImages([
      {
        id: '1',
        url: testImage,
      },
      {
        id: '2',
        url: testImage,
      },
      {
        id: '3',
        url: testImage,
      },
    ]);
  }, []);

  const handleClickCopyTextArea = useCallback((e, link_id) => {
    const element = e.target.closest('.link').querySelector('textarea');
    element.select();
    document.execCommand('copy');
    const selection = window.getSelection;
    if (selection) {
      selection()?.removeAllRanges();
      setCopied(true);
      setLinkSelected(link_id);
      setTimeout(() => {
        setCopied(false);
        setLinkSelected('');
      }, 500);
    }
  }, []);
  return (
    <>
      <Container className="btn-choise mx-auto overflow-auto my-3">
        <div className="min-width d-flex justify-content-center">
          <button
            type="button"
            className={`${choiseShare === 'emails' && 'active'}`}
            onClick={() => setChoiseShare('emails')}
          >
            Emails
          </button>
          <button
            type="button"
            className={`${choiseShare === 'social' && 'active'}`}
            onClick={() => setChoiseShare('social')}
          >
            Social Posts
          </button>

          <button
            type="button"
            className={`${choiseShare === 'images' && 'active'}`}
            onClick={() => setChoiseShare('images')}
          >
            Images
          </button>
          {/* <button
            type="button"
            className={`${choiseShare === 'headlines' && 'active'}`}
            onClick={() => setChoiseShare('headlines')}
          >
            Headlines
          </button>
          <button
            type="button"
            className={`${choiseShare === 'videos' && 'active'}`}
            onClick={() => setChoiseShare('videos')}
          >
            Videos
          </button>
          <button
            type="button"
            className={`${choiseShare === 'flyers' && 'active'}`}
            onClick={() => setChoiseShare('flyers')}
          >
            Flyers
          </button>
          <button
            type="button"
            className={`${choiseShare === 'business' && 'active'}`}
            onClick={() => setChoiseShare('business')}
          >
            Business Cards
          </button> */}
        </div>
      </Container>

      {choiseShare === 'emails' && (
        <>
          {emails.map((banner, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-3">
              <Card className="p-3 p-sm-4 h-100">
                <h3 className="mb-0">Subject:</h3>
                <LinkCopy
                  title="Copy subject email"
                  className="subject link d-flex justify-content-between align-items-end py-1 p-relative"
                >
                  <textarea
                    className={`py-2 w-100 bg-transparent border-0 `}
                    value={banner.subject}
                    readOnly
                    aria-label="button"
                    onClick={(e) => handleClickCopyTextArea(e, banner.id)}
                  />
                </LinkCopy>

                <h3 className="mb-0">Body:</h3>
                <LinkCopy
                  title="Copy body email"
                  className="body link d-flex justify-content-between align-items-end py-1 p-relative"
                >
                  <textarea
                    className={`py-2 w-100 bg-transparent border-0 `}
                    value={banner.body}
                    readOnly
                    aria-label="button"
                    onClick={(e) => handleClickCopyTextArea(e, banner.id)}
                  />
                </LinkCopy>
                <Flag
                  show={copied && linkSelected === banner.id}
                  className="px-3 pt-1 p-absolute"
                >
                  <p className="mb-0 text-green">{language.footer.copy}</p>
                </Flag>
              </Card>
            </div>
          ))}
        </>
      )}
      {choiseShare === 'social' && (
        <>
          {socials.map((social, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-3">
              <Card className="body p-3 p-sm-4 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src={user.avatar.avatar_url}
                      alt="Avatar"
                      className="img-profile"
                    />
                    <p className="username small mb-0 ml-2">
                      <b>{user.name || user.username}</b>{' '}
                      {language.social_media.p}
                    </p>
                  </div>
                </div>
                <h2 className="article-title">
                  {`Post ${index + 1}: ${social.title}`}
                </h2>
                <h3
                  className="article-title"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: social.subtitle.replace(/\n/g, '<br>'),
                  }}
                />
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: social.body.replace(/\n/g, '<br>'),
                  }}
                  className="h-body py-2 w-100 bg-transparent border-0"
                />

                <div className="d-flex flex-wrap mt-4 pb-3">
                  <small className="w-100 text-center mb-4">
                    {language.social_media.small}
                  </small>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <FacebookShareButton
                      quote={social.body.replace(/<\/?b>/g, '')}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={facebook}
                        alt={language.social_media.img_1}
                        className="my-2"
                      />
                    </FacebookShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <TwitterShareButton
                      title={`${social.body.replace(/<\/?b>/g, '')}\n`}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={twitter}
                        alt={language.social_media.img_2}
                        className="my-2"
                      />
                    </TwitterShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <WhatsappShareButton
                      title={`${social.body.replace(/<\/?b>/g, '*')}`}
                      separator={`\n`}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={whatsapp}
                        alt={language.social_media.img_3}
                        className="my-2"
                      />
                    </WhatsappShareButton>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </>
      )}
      {choiseShare === 'images' && (
        <>
          {images.map((image, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-3">
              <Card className="body p-3 p-sm-4 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src={user.avatar.avatar_url}
                      alt="Avatar"
                      className="img-profile"
                    />
                    <p className="username small mb-0 ml-2">
                      <b>{user.name || user.username}</b>{' '}
                      {language.social_media.p}
                    </p>
                  </div>
                </div>

                <div className="">
                  <img
                    src={image.url}
                    alt="Promotional"
                    className="w-100 imgs"
                  />
                </div>

                <div className="d-flex flex-wrap mt-4 pb-3">
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <FacebookShareButton
                      quote={image.url.replace(/<\/?b>/g, '')}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="pe-none btn-share rounded-pill w-100"
                    >
                      <img
                        src={facebook}
                        alt={language.social_media.img_1}
                        className="my-2"
                      />
                    </FacebookShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <TwitterShareButton
                      title={`${image.url.replace(/<\/?b>/g, '')}\n`}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="pe-none btn-share rounded-pill w-100"
                    >
                      <img
                        src={twitter}
                        alt={language.social_media.img_2}
                        className="my-2"
                      />
                    </TwitterShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <WhatsappShareButton
                      title={`${image.url.replace(/<\/?b>/g, '*')}`}
                      separator={`\n`}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="pe-none btn-share rounded-pill w-100"
                    >
                      <img
                        src={whatsapp}
                        alt={language.social_media.img_3}
                        className="my-2"
                      />
                    </WhatsappShareButton>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Swipe;

import styled from 'styled-components';

interface IFlagProps {
  show: boolean;
}

export const Container = styled.div`
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
  }
  button {
    color: #116bff;
    border: 1px solid #116bff;
    background: transparent;
    font-size: 16px;
    width: 144px;
    border-radius: 14px;
    padding: 7px;
    transition: all 0.3s ease-in-out;
    margin: 0 10px;

    :hover {
      color: #fff;
      background: #116bff;
    }
  }

  .active {
    color: #fff;
    background: #116bff;
  }

  .min-width {
    min-width: 1150px;
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  //min-height: 526px;

  h3 {
    color: #242526;

    font-size: 16px;

    font-weight: 600;
    line-height: normal;
  }

  .subject {
    background: transparent;
  }

  .body {
    background: transparent;
  }

  .subject textarea {
    height: 85px;
  }

  .body textarea {
    height: 510px;
  }

  textarea {
    color: #515151 !important;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 300;
    line-height: 140%;
  }

  .h-body {
    height: 400px;
  }

  .input-tag {
    font-size: 14px;
    color: #515151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #f2f2f2;
    height: 50px;
    text-align: center;
  }

  .btn-code {
    color: #116bff;
    width: 43%;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    border-radius: 27px;
    background: #fff;
    border: 2px solid #116bff;
    padding: 2px;
    :hover {
      color: #fff;
      background: #116bff;
      border: 2px solid #116bff;
    }
  }

  .btn-code-active {
    color: #fff;
    background: #116bff;
    border: 2px solid #116bff;
  }

  .imgs {
    border: 1px solid #116bff;
  }

  .radius {
    border-radius: 20px !important;
  }

  .height-default {
    height: 310px;
  }

  .image-banner {
    max-width: fit-content;
    border-radius: 15px;
    border: 2px solid #116bff;
  }

  .article-title {
    color: #242526;
    font-size: 16px;
    font-weight: 600;
    min-height: 44px;
  }

  .description-h {
    height: 95px;
    overflow: auto;
  }

  .img-profile {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 2px rgb(228 228 228 / 50%);
    box-sizing: border-box;
    //filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.24));
    background-color: #303030;
  }

  textarea {
    //height: 29px;
    //max-height: 180px;
    resize: none;
    //overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;

    :focus {
      outline: none;
      cursor: unset;
      background-color: #18191a;
      box-shadow: none;
    }

    ::placeholder {
      color: #828282;
    }
  }

  .username {
    color: #242526;
  }

  .tinytext {
    color: #8c8c8c;
    font-weight: 300;
  }

  small {
    color: #8c8c8c;
  }

  button.btn-top {
    padding: 1px;
    width: 40%;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .btn-share {
    background-color: #116bff !important;
  }

  button {
    transition-duration: 0.2s;
  }

  button:hover {
    opacity: 0.8;
  }

  button.border-delete {
    color: #ff4d77;
  }

  img {
    border-radius: 20px;
  }

  .border-golden {
    padding: 1px !important;
  }

  @media screen and (max-width: 767px) {
    .description-h {
      height: auto;
    }

    .body textarea {
      height: 500px;
    }
  }
`;

export const LinkCopy = styled.div`
  background: #f2f2f2;

  textarea {
    //height: auto;
    color: #626262;
  }
  input {
    cursor: pointer;
    font-size: 14px;
    color: #515151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Flag = styled.div<IFlagProps>`
  right: 15px;
  top: -20px;
  border-radius: 10px;
  background: #f1f1f1;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  ::after {
    content: '';
    width: 5px;
    height: 5px;
    background: #f1f1f1;
    position: absolute;
    bottom: -2px;
    z-index: 10000;
    left: 50%;
    transform: rotate(45deg);
  }
`;
